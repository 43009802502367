<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
    >
      <v-card>
        <v-toolbar
          flat
          color="#018656"
          dark
        >
        <v-btn
            icon
            dark
            @click="$emit('close', false)"
          >
            <v-icon color="white" dense small>fas fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>Datas</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="mt-5">
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="dadosFormatados"
            class="elevation-1"
          ></v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="$emit('close', false)"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import moment from "moment";
  export default {
    data () {
      return {
        headers: [
          { text: 'Data do pedido', value: 'data_venda', sortable: false },
          { text: 'Data de postagem', value: 'data_considerada', sortable: false },
          { text: 'Data prevista', value: 'data_prevista', sortable: false },
          // { text: 'Data prometida', value: 'data_prometida', sortable: false },
          { text: 'Data de entrega', value: 'data_entrega', sortable: false },
          { text: 'Data da última posição', value: 'data_ultima_posicao', sortable: false },
          // { text: 'Data do cancelamento', value: 'data_cancelamento', sortable: false }
        ]
      }
    },

    props: {
      datas: Array,
      dialog: Boolean
    },

    computed: {
      dadosFormatados() {
        return this.datas.map(item => {
          return {
            data_considerada: moment(item.data_considerada).format("DD/MM/YYYY"),
            data_prevista: moment(item.data_prevista).format("DD/MM/YYYY"),
            data_entrega: moment(item.data_entrega).format("DD/MM/YYYY"),
            data_venda: moment(item.data_venda).format("DD/MM/YYYY"),
            data_ultima_posicao: moment(item.data_ultima_posicao).format("DD/MM/YYYY")
          };
        });
      }
    },
  }
</script>