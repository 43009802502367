<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >

      <v-card
        class="mx-auto"

      >
        <v-toolbar
          flat
          color="#018656"
          dark
        >
        <v-btn
            icon
            dark
            @click="$emit('close', false)"
          >
            <v-icon color="white" dense small>fas fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>Alertas</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pt-5">
          <span v-for="(index, ocorrencia) in ocor.items[0]" :key="`${ocorrencia}${index}`" >
            <v-chip class="mx-2" v-if="index > 0" label :color="index > 0 ? 'yellow' : ''">
              <span :class="index > 0 ? 'black--text': ''">{{ ocorrencia }}</span>
            </v-chip>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="$emit('close', false)"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
  export default {
    data () {
      return {
        codigo_empresa: '',
        ocor: {
          items: []
        }
      }
    },

    props: {
      consulta: Object,
      dialog: Boolean
    },

    methods: {
      async carregarDetalhes(item) {
        await this.carregarDetalhesObjeto.call(this, item);
      },
      async carregarDetalhesObjeto(itemDetails) {
        let url = `${baseApiUrl}/detalhes-ocorrencias`;
        url += `?numero_rastreio=${itemDetails.numero_rastreio}`;
        url += `&cod_empresa=${itemDetails.cod_empresa}`;
        await axios
          .get(url)
            .then((res) => {
              this.ocor.items = [{
                "Atrasado": res.data.dados[0].atrasado,
                "Devolvido": res.data.dados[0].devolvido,
                "Tentativa Entrega": res.data.dados[0].tentativa_entrega,
                "Extravio": res.data.dados[0].extravio,
                "Roubo": res.data.dados[0].roubo,
                "Falha Remetente": res.data.dados[0].falha_remetente,
                "Falha Fiscal": res.data.dados[0].falha_fiscal,
                "Aguardando Retirada": res.data.dados[0].aguardando_retirada,
                "Avaria": Number(res.data.dados[0].avaria),
                "Alerta": Number(res.data.dados[0].alerta),
                "Pos-Entrega": res.data.dados[0].pos_entrega
              }];
            })
            .catch((error) => console.log(error))
        },
    },

    watch: {
      dialog(val) {
        if(val) {
          this.carregarDetalhes.call(this, this.consulta.item);
        }
      }
    }
  }
</script>